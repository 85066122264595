// src/components/footer/footer.js

import React from "react"
import { Link } from "gatsby"
import dataMenus from "../../data/yaml/menus.yaml"
import { useSiteMetadata } from "../../hooks/useSiteMetadata"
import { getPage } from "../../utils/helper"
import { DarkSection } from "../sections"
import { List } from "../lists"

export const orderMapping = { 0: "I.", 1: "II.", 2: "III." }

const ServiceMenu = () => (
  <div className="pb-12 mx-auto md:pb-16 xl:pb-24">
    <h3 className="pb-4 md:pl-6 lg:text-smLG xl:text-baseLG lg:pb-8">
      Prof. Pepe Jürgens, Leistungen User Experience
    </h3>
    <div className="flex-auto md:flex">
      <ServiceList service={dataMenus.serviceMenus[0]} index={0} />
      <ServiceList service={dataMenus.serviceMenus[1]} index={1} />
      <ServiceList service={dataMenus.serviceMenus[2]} index={2} />
    </div>
  </div>
)


const ServiceList = ({ service: { pageId, list }, index }) => {
  const { pages } = useSiteMetadata()
  const { url: to, label: headline } = getPage(pageId, pages)

  return (
    <div className="md:w-full md:flex-1/3 md:ml-6">
      {/* @remember Group-hover
          If you need to style a child element when hovering over a specific parent element, add the .group class to the parent element and add the group-hover: prefix to the utility on the child element. 
        */}
      <Link to={to} className="group lg:text-smLG xl:text-baseLG">
        <h2 className="relative font-normal group-hover:underline text-primary-400 lg:text-smLG xl:text-baseLG">
          <span className="absolute font-normal -left-5 lg:-left-7">
            {orderMapping[index]}
          </span>
          {headline}
        </h2>
        <List list={list} liPd="lg:pb-1" />
      </Link>
    </div>
  )
}

const FooterMenu = () => {
  const { footerMenu } = dataMenus
  return (
    <div className="">
      <ul className="sm:flex sm:justify-center md:justify-end md:pr-17 text-smSM md:text-smMD lg:text-smLG">
        {footerMenu.map((obj, index) => (
          <FooterLink {...obj} key={index} />
        ))}
      </ul>
    </div>
  )
}

const FooterLink = ({ url, label }) => (
  <li className="pb-1 sm:pb-0 sm:px-1 md:px-0 md:pl-2 text-smSM md:text-smMD lg:text-smLG">
    {url !== undefined && (
      <Link className="px-1 hover:underline" to={url}>
        {label}
      </Link>
    )}
    {url === undefined && label}
  </li>
)


const Footer = () => (
  <DarkSection className="pb-8 px-7 md:px-4">
    <ServiceMenu />
    <FooterMenu />
  </DarkSection>
)

export default Footer
