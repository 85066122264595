// src/utils/helper.js

import filter from "ramda/src/filter"
import propEq from "ramda/src/propEq"
import find from "ramda/src/find"
import reduce from "ramda/src/reduce"
import uniq from "ramda/src/uniq"
import concat from "ramda/src/concat"
// import path from "ramda/src/path"

// Find converts data from menus in gatsby.configs.js
export const getMainPages = (arr) => filter(propEq("pageType", "main"), arr)

// export const getRulesMenuData = (arr) =>
//   filter(propEq("menu_type", "rules"), arr)

const findId = (id, obj) => {
  return find(propEq("id", id))(obj)
}

// Find converts data from menus in gatsby.configs.js
export const getPage = (id, arr) => {
  const obj = findId(id, arr)

  failIfNotExist(
    obj,
    "in toButtonData: Did not find an object, probaly missing obj.id in arr objects"
  )

  return obj
}

// TODO this case does not work list-disc list-inside || flex flex-col || bg-warm-gray-800
// when the previx specifies more than one cause 

export const overwriteTailwindClassNames = (
  classNameDefaults,
  classsNameReplace
) => {
  // break: for speed
  if (classsNameReplace === "") {
    return classNameDefaults
  }

  const arrClassNameDefaults = classNameDefaults.split(" ")
  const arrClassNameReplace = classsNameReplace.split(" ")

  const replaceSameProps = (arr, oldClassName) => {
      const classNameProp = oldClassName.split("-")[0]
      const strRegexClassName = classNameProp + "-(\\d+|\\w+)"
      const newClassName = classsNameReplace.match(
        new RegExp(strRegexClassName)
      )

      if (newClassName !== null) {
        arr.push(newClassName)
      } else {
        arr.push(oldClassName)
      }
      return arr
    }

  const arrWithNewProps = reduce(replaceSameProps, [], arrClassNameDefaults)
  const arrOldAndNew = uniq( concat(arrWithNewProps, arrClassNameReplace) );

  return arrOldAndNew.join(" ")
}

export const fail = (str) => {  
  throw new Error(str)
}
export const failIfNotExist = (para, str) => {
  if (para === undefined) {
    fail(str)
  }
}
