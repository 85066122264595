// src/components/sections/sections.js

import React from "react"
import { overwriteTailwindClassNames as overwriteTw } from "../../utils/helper"



export const Section = ({ className = "", children }) => (
  <div className={overwriteTw("min-w-320", className)}>
    <div className="max-w-screen-lg mx-auto">{children}</div>
  </div>
)

const sectionClasses = "px-3 pt-16 md:pt-24 lg:pt-28 pb-16"
export const BrightSection = ({className='', children}) => (
  <Section
children ={children}
    className={overwriteTw(`${sectionClasses} bg-white text-black`, className)}
  />
)
// Decorator Row
export const DarkSection = ({ className = "", children }) => (
  <Section
    children={children}
    className={overwriteTw(
      `${sectionClasses} bg-warm-gray-900 text-white`,
      className
    )}
  />
)

// Special Row banner and hero
const bannerSectionClasses = "min-w-320 flex flex-col w-full pb-12" 
 export const BannerSection = ({ className = "", children }) => (
   <div className={overwriteTw(bannerSectionClasses, className)}>
     <div className="px-3">{children}</div>
   </div>
 )

// export const RowFlexCentered = (props) => (
//   <Row
//     {...props}
//     className="flex flex-col justify-center align-middle h-screen-90vh"
//   />
// )

