// src/hooks/useSiteMetadata.js

import { useStaticQuery, graphql } from "gatsby"
export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            name
            claim
            pages {
              id
              url
              label
              labelLong
              pageType
            }
          }
        }
      }
    `
  )
  return site.siteMetadata
}