// src/components/lists/lists.js

import React from "react"
import { overwriteTailwindClassNames as overwriteTw } from "../../utils/helper"

export const DiscList = (props) => (
  <List {...props} className="list-disc list-inside sm:px-10 sm:list-outside" />
)

const listDefaultClassName = `pb-4`
export const List = ({ list, className = "", liPd = "pb-0" }) => (
  <ul className={overwriteTw(listDefaultClassName, className)}>
    {list.map(({ label }, index) => (
      // TODO last el in list without padding-bottom, else double pd from ul and li
      <li className={liPd} key={index}>
        {label}
      </li>
    ))}
  </ul>
)
